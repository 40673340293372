.event_list {
    padding: 16px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
    min-height: 194px;
    border-radius: 8px;
    width: 99%;
    margin: 0.5em;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
}

.event_list:hover {    
    box-shadow: 1px 1px 3px #F79702;
}

.event_list .event_image {
    flex: 30%;
}

.event_list .event_image a {
    width: 100%;
    border: 1px solid #eee;
    display: block;
}

.event_list .event_content {
    flex: 70%;
}

.event_list .event_content div:first-child {
    padding-top: 0;
    padding-bottom: 0;
}

.event_list .event_content a {
    text-decoration: none;
}

.event_list .event_calendar {
    position: absolute; 
    right: 15px;
    bottom: 15px;
}

.event_list .event_calendar .fab {
    width: auto;
    height: auto; 
    box-shadow: 1px 1px 5px #ddd;
}

.event_list .event_calendar .fab {
    padding: 0.75em;
    height: 48px;
    width: 48px;
}

.event_list h3 {
    color: #000;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 0.03em;
}

.event_list .event_avatar_holder {
    display: flex;    
    margin-top: 10px;
}

div[dir="rtl"][class="lang"] .event_list .event_avatar_holder p {
    margin-right: 10px;
}

.event_list .event_avatar_holder img {
    background-color: #ffffff;
}

.event_list .event_avatar_holder div {
    border-radius: 50%;
}

.event_list .event_avatar_holder {
    font-size: 14px;
}

.event_list .event_status div {
    padding: 7px 12px;
    border-radius: 10px 0px 10px 0;
    line-height: 12px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
}

.event_list .event_status {
    width: auto;
    float: right;
    margin: 15px;
}

.event_list .event_start_date {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: #fff;
    border-radius: 3px;
    font-weight: normal;
    color: #000;
    border: 2px solid #000;
    padding: 0.1em 0.5em;
    margin-bottom: 0;
    border-radius: 3px;
}

.event_list .event_time {
    margin-top: 15px;
    border-top: 1px solid #ddd;
}

.event_list .event_time p {
    display: inline-block;
    color: #000;
}

div[dir="ltr"][class="lang"] .event_list .event_time p {    
    padding-right: 10px;
    border-right: 1px solid #000;
    margin-right: 10px;
}

div[dir="rtl"][class="lang"] .event_list .event_time p {    
    padding-left: 10px;
    border-left: 1px solid #000;
    margin-left: 10px;
}

div[dir="ltr"][class="lang"] .event_list .event_time p:last-child {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
}

div[dir="rtl"][class="lang"] .event_list .event_time p:last-child {
    padding-left: 0;
    border-left: 0;
    margin-left: 0;
}

div[dir="ltr"][class="lang"] .event_list h3 {
    text-align: left;
}

div[dir="rtl"][class="lang"] .event_list h3 {
    text-align: right;
}

.event_list .event_time p span {
    text-transform: capitalize;
}

@media (max-width: 1024px) {   
    .event_list { 
        margin: 0.5em auto;
    }
}

@media (max-width: 768px) {
    .event_list {
        width: 99%;
        margin: 0.5em;
    }

    .event_list .event_info {
        text-align: right;
    }

    .event_list .event_image,
    .event_list .event_content {
        flex: 100%;
    }

    .event_list .event_content h3 {
        margin-top: 10px;
    }
}

@media (max-width: 425px) {
    .event_list {
        width: 98%;
        margin: 0.25em;
    }

    .event_list .event_info {
        text-align: left;
        margin-top: 20px;
    }
    
}