.post_list {
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-bottom:30vh;
  background-color: #F0F2F5;
}

.post_list .empty_list_placeholder {
  display: flex;
  justify-content: center;
  color: #999999;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 2em;
}

.post_list section a:first-child{
  background-color: #ffffff;
}

.post_list section a h3 {
  font-weight: normal;
  color: #202124;
  line-height: 17px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 3px;
  width: 95%;
}

.post_list section a h3 time {
  text-transform: initial;
}

.post_list section a h3 span {
  font-size: 14px;
  text-transform: initial;
}
